@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --white: 255 255 255;
  --black: 0 0 0;
  --transparent: 0 0 0;
  --blue-300: 147 197 253;
  --blue-400: 96 165 250;
  --blue-500: 59 130 246;
  --indigo-300: 165 180 252;
  --violet-200: 221 214 254;
}

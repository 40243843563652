.aurora {
  --white: 255 255 255;
  --black: 0 0 0;
  --transparent: 0 0 0;
  --blue-300: 147 197 253;
  --blue-400: 96 165 250;
  --blue-500: 59 130 246;
  --indigo-300: 165 180 252;
  --violet-200: 221 214 254;
}

.auroraBackground {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(244, 245, 247);
  color: rgb(15 23 42);
  overflow: hidden;
}

.auroraContainer {
  position: absolute;
  inset: 0;
  overflow: hidden;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.95) 0%, rgba(244, 245, 247, 0.9) 100%);
}

.auroraEffect {
  --aurora: repeating-linear-gradient(
    135deg,
    rgba(124, 77, 255, 0.18) 0%,
    rgba(147, 197, 253, 0.18) 50%,
    rgba(124, 77, 255, 0.18) 100%
  );
  position: absolute;
  inset: -100%;
  animation: aurora 20s linear infinite;
  background-image: var(--aurora);
  opacity: 0.8;
  transform: scale(2);
}

@keyframes aurora {
  from {
    transform: rotate(0deg) scale(2);
  }
  to {
    transform: rotate(360deg) scale(2);
  }
}
